<template>
  <Container v-if="$preview && !current1ozSale">
    <DevNote>
      Alchemy Preview: This "Collection oneozsale" is only visible on the
      storefront during an active one-oz-sale.
    </DevNote>
  </Container>
  <AllTheOils v-if="$preview || current1ozSale" :h-padding="true" />
</template>

<script setup>
  const saleStore = useSaleStore()
  const current1ozSale = computed(() => saleStore.activeSale)
</script>
